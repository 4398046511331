export const COLORS = [
    "#A142F1",
    "#8AEBCE",
    "#5855E0",
    "#E6D95D",
    "#E65454",
    "#F98FC2",
    "#A1D338",
    "#AFC9CF",
    "#4AB8E8",
];
